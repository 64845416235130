import React, { useEffect, useRef, useState } from "react";
import Isotope from "isotope-layout";
import ProjectDetailsModal from "./ProjectDetailsModal";
const Portfolio = () => {
  // init one ref to store the future isotope object
  const isotope = useRef();
  // store the filter keyword in a state
  const [filterKey] = useState("*");
  const [imagesLoaded, setimagesLoaded] = useState(0);
  const [selectedProjectDetails, setSelectedProjectDetails] = useState();
  const [isOpen, setIsOpen] = useState(false);

  const htmlElement = document.getElementsByTagName("html")[0];
  const isRtl = htmlElement.getAttribute("dir") === "rtl";

  const filters = {
    ECOMM: "",
    UIUX: "UI/UX",
    SALES: "Sales Channels",
    WEB: "Web Development",
    GRAPHIC: "Graphic Design",
    SEO: "SEO",
  };

  const types = {
    IMAGE: "image",
    VIDEO: "video",
    DOCUMENT: "document",
  };

  const projectsData = [
    {
      title: "KFC - Yum Brands!",
      type: types.DOCUMENT,
      document: {
        projectInfo: `KFC, the renowned fast-food chain headquartered in Louisville, KY, is the second-largest restaurant chain in the
          world, second only to McDonald's. I am proud to be a part of the team responsible for developing the KFC online
          ordering application, which allows customers to easily order their favorite food items from the comfort of their
          own homes, while also granting them access to exclusive deals. This application has played a critical role in
          helping KFC and its parent company Yum continue to succeed during times of shutdowns and reduced foot traffic,
          offering customers a convenient and accessible way to enjoy their meals.`,
        client: "KFC - Yum Brands",
        technologies:
          "Nextjs, React, HTML5, CSS3, JavaScript, Contentful, Node",
        industry: "",
        date: "Current",
        url: {
          name: "KFC - Yum Brands!",
          link: "https://kfc.com",
        },

        sliderImages: ["images/projects/kfc.png"],
      },

      thumbImage: "images/projects/kfc.png",

      categories: [],
    },
    {
      title: "Blue Cross Blue Shield",
      type: types.DOCUMENT,
      document: {
        projectInfo: `This is a powerful team management application designed to streamline and enhance workflow in any
        environment, whether it's agile, waterfall, or ad hoc. With this application, managers can effortlessly
        communicate with and assign tasks to team members, while monitoring progress in real-time. Meanwhile, team
        members can leave detailed notes and feedback, and easily track their task list through the application. Overall,
        this application greatly simplifies team management and allows for more intelligent, efficient collaboration.`,
        client: "Twinspires",
        technologies: "React, HTML5, CSS3, JavaScript, Contentful, Node",
        industry: "",
        date: "2023",
        url: {
          name: "Blue Cross Blue Shield",
          link: "https://www.bcbs.com/",
        },

        sliderImages: ["images/projects/bcbs.png"],
      },

      thumbImage: "images/projects/bcbs.png",

      categories: [],
    },
    {
      title: "Integrity Express Logistics",
      type: types.DOCUMENT,
      document: {
        projectInfo: `The application I developed effectively bridges the communication gap between brokers and clients in the logistics
        industry. With a user-friendly dashboard, clients can easily access information on their current, past, and
        upcoming loads with their broker, fostering transparency and accountability. Moreover, the application enables
        seamless communication between brokers and clients throughout the entire relationship, ensuring that all
        parties are on the same page and making the logistics process as smooth and efficient as possible.`,
        client: "KFC - Yum Brands",
        technologies:
          "Nextjs, React, HTML5, CSS3, JavaScript, Contentful, Node, Material-UI, Design, Architecture",
        industry: "",
        date: "2020",
        url: {
          name: "IEL",
          link: "https://ielfreight.com/nexus/",
        },

        sliderImages: ["images/projects/IEL.png"],
      },

      thumbImage: "images/projects/IEL.png",

      categories: [],
    },
    {
      title: "Ace Gymnastics",
      type: types.DOCUMENT,
      document: {
        projectInfo:
          "Ace Gymnastics is a cherished family-owned gymnastics facility, situated in the heart of Bardstown, KY. Our primary objective for this project was to establish a robust online presence and enhance the convenience of class registration and event booking for parents. To achieve this, we integrated Jackrabbit, a highly acclaimed management system that simplifies class registration processes for parents, enabling them to register and pay online seamlessly. Additionally, we incorporated a user-friendly calendar that displays all upcoming classes and events. We have also ensured that our website is mobile-friendly, allowing parents to register for classes on-the-go, anytime and anywhere.",
        client: "Ace Gymnastics",
        technologies:
          "React, HTML5, CSS3, JavaScript, Material-UI, Contentful, Node, Heroku",
        industry: "",
        date: "2022",
        url: {
          name: "ACE Gymnastics",
          link: "https://www.ace-gymnastics.com",
        },

        sliderImages: [
          "images/projects/ace-gymnastics1.png",
          "images/projects/ace-gymnastics2.png",
          "images/projects/ace-gymnastics3.png",
        ],
      },

      thumbImage: "images/projects/acelogo.jpeg",

      categories: [],
    },
    {
      title: "Moore Quality Finishes",
      type: types.DOCUMENT,
      document: {
        projectInfo:
          "I had the privilege of collaborating with Moore Quality Finishes, a distinguished painting company, on creating a custom website that perfectly reflects their standard of excellence. This user-centric platform highlights the exceptional services they offer, from interior painting to exterior finishes. One of the website's unique features is a dedicated section that showcases the team's accomplished projects, allowing visitors to appreciate the superior quality of their work firsthand. In addition, a 'Meet the Team' page was incorporated, giving a human touch to the brand and allowing potential clients to familiarize themselves with the professionals behind the craft. Beyond the website development, I assisted Moore Quality Finishes in establishing and optimizing their Google Business Account, effectively broadening their digital footprint and enhancing their visibility on search engine results. This comprehensive project not only boosted their online presence but also provided a streamlined platform for customers to explore and engage with their wide array of services.",
        client: "Moore Quality Finishes",
        technologies:
          "React, HTML5, CSS3, JavaScript, Material-UI, Contentful, Node, Heroku",
        industry: "",
        date: "2023",
        url: {
          name: "Moore Quality",
          link: "https://www.moorequalityfinishes.com",
        },

        sliderImages: [
          "images/projects/moorequality1.png",
          "images/projects/moorequality2.png",
          "images/projects/moorequality3.png",
          "images/projects/moorequality4.png",
        ],
      },

      thumbImage: "images/projects/MooreQualityFinishesLogo.png",

      categories: [],
    },
    {
      title: "Twinspires - Churchill Downs",
      type: types.DOCUMENT,
      document: {
        projectInfo: `Twinspires is the premier online destination for legal wagering in the United States, offering a range of options
          such as sports betting, online casino, and advance deposit wagering. As the lead front-end developer, I was
          responsible for crafting a seamless user experience for customers, with a specific focus on the horse racing
          wagering portion of the software. This critical application drives a significant portion of Churchill Downs' revenue
          and played a key role in the company's success during the COVID-19 pandemic when many were forced to stay
          home. My contributions helped to ensure that Twinspires remained the top choice for legal wagering, providing a
          user-friendly and engaging platform for customers.`,
        client: "Twinspires",
        technologies:
          "Angular, React, HTML5, CSS3, JavaScript, Contentful, Node",
        industry: "",
        date: "2023",
        url: {
          name: "Twinspires",
          link: "www.twinspires.com",
        },

        sliderImages: ["images/projects/twinspires.png"],
      },

      thumbImage: "images/projects/twinspires.png",

      categories: [],
    },
  ];

  // initialize an Isotope object with configs
  useEffect(() => {
    isotope.current = new Isotope(".portfolio-filter", {
      itemSelector: ".filter-item",
      layoutMode: "masonry",
      originLeft: !isRtl,
    });

    // cleanup
    return () => {
      isotope.current.destroy();
    };
  }, [isRtl]);

  // handling filter key change
  useEffect(() => {
    if (imagesLoaded) {
      filterKey === "*"
        ? isotope.current.arrange({ filter: `*` })
        : isotope.current.arrange({ filter: `.${filterKey}` });
    }
  }, [filterKey, imagesLoaded]);

  // const handleFilterKeyChange = (key) => () => setFilterKey(key);

  const getKeyByValue = (value) => {
    return Object.keys(filters).find((key) => filters[key] === value);
  };

  const getFilterClasses = (categories) => {
    if (categories.length > 0) {
      let tempArray = [];
      categories.forEach((category, index) => {
        tempArray.push(getKeyByValue(category));
      });
      return tempArray.join(" ");
    }
  };

  return (
    <>
      <section id="portfolio" className={"section bg-light"}>
        <div className={"container"}>
          {/* Heading */}
          <p className="text-center mb-2 wow fadeInUp">
            <span className="bg-primary text-dark px-2">Portfolio</span>
          </p>
          <h2
            className="text-10 fw-600 text-center mb-5 wow fadeInUp"
            style={{ marginBottom: "5rem" }}
          >
            Recent Projects
          </h2>
          {/* Heading end*/}
          {/* Filter Menu */}
          {/* <ul
            className={
              "portfolio-menu nav nav-tabs fw-600 justify-content-start justify-content-md-center border-bottom-0 mb-5 wow fadeInUp"
            }
          >
            <li className="nav-item">
              <button
                className={"nav-link " + (filterKey === "*" ? "active" : "")}
                onClick={handleFilterKeyChange("*")}
              >
                All
              </button>
            </li>
            {Object.keys(filters).map((oneKey, i) => (
              <li className="nav-item" key={i}>
                <button
                  className={
                    "nav-link " + (filterKey === oneKey ? "active" : "")
                  }
                  onClick={handleFilterKeyChange(oneKey)}
                >
                  {filters[oneKey]}
                </button>
              </li>
            ))}
          </ul> */}
          {/* Filter Menu end */}
          <div className="portfolio wow fadeInUp">
            <div className="row portfolio-filter filter-container g-4">
              {projectsData.length > 0 &&
                projectsData.map((project, index) => (
                  <div
                    className={
                      "col-sm-6 col-lg-4 filter-item " +
                      getFilterClasses(project.categories)
                    }
                    key={index}
                  >
                    <div className="portfolio-box">
                      <div className="portfolio-img">
                        <img
                          onLoad={() => {
                            setimagesLoaded(imagesLoaded + 1);
                          }}
                          className="img-fluid d-block portfolio-image"
                          src={project.thumbImage}
                          alt=""
                        />
                        <div
                          className="portfolio-overlay"
                          onClick={() => {
                            setSelectedProjectDetails(projectsData[index]);
                            setIsOpen(true);
                          }}
                        >
                          <button className="popup-ajax stretched-link border-0 p-0 ">
                            {" "}
                          </button>
                          <div className="portfolio-overlay-details">
                            <p className="text-primary text-8">
                              {project.type === types.DOCUMENT && (
                                <i className="fas fa-file-alt"></i>
                              )}
                              {project.type === types.IMAGE && (
                                <i className="fas fa-image"></i>
                              )}
                              {project.type === types.VIDEO && (
                                <i className="fas fa-video"></i>
                              )}
                            </p>
                            <h5 className="text-white text-5">
                              {project?.title}
                            </h5>
                            <span className="text-light">
                              {project.document.industry}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </section>
      {/* Modal */}
      {isOpen && (
        <ProjectDetailsModal
          projectDetails={selectedProjectDetails}
          setIsOpen={setIsOpen}
        ></ProjectDetailsModal>
      )}
    </>
  );
};

export default Portfolio;
