import React from "react";

const Services = () => {
  // services details
  const services = [
    {
      name: "Frontend Mastery",
      desc: "Harnessing the potential of cutting-edge Javascript frameworks like React and Angular, I craft dynamic and responsive user interfaces. My creations are not just visually appealing but are optimized for both mobile and desktop, always adhering to the latest accessibility standards.",
      icon: "fas fa-desktop",
    },
    {
      name: "Backend Development",
      desc: "With a solid foundation in PHP, Python, and Node/Express APIs, I design and develop robust backend solutions. My expertise ensures data integrity, scalability, and efficient communication between server and client.",
      icon: "fas fa-server",
    },
    {
      name: "Database Expertise",
      desc: "Be it SQL or NoSQL, my proficiency in database technologies ensures that your application data is stored, retrieved, and manipulated with utmost efficiency. I build databases that are robust, scalable, and tailored to the unique needs of each project.",
      icon: "fas fa-database",
    },
    // {
    //   name: "App Design & Develop",
    //   desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text.",
    //   icon: "fas fa-paint-brush",
    // },
    // {
    //   name: "Business Analysis",
    //   desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text.",
    //   icon: "fas fa-chart-area",
    // },
    {
      name: "Fullstack Integration",
      desc: "My holistic approach as a Full-Stack Developer means I see the bigger picture. From frontend to backend, from database design to API integration, I ensure all components work seamlessly together, delivering a cohesive and powerful application.",
      icon: "fas fa-layer-group",
    },
    {
      name: "Collaborative Spirit",
      desc: "Beyond technical skills, I deeply value collaboration and communication. Building software is a team effort, and I'm committed to fostering long-lasting relationships with clients and team members alike. My collaborative nature ensures that projects are aligned with goals and visions from start to finish.",
      icon: "fas fa-users",
    },
    {
      name: "Problem Solving & Innovation",
      desc: "Whether it's creating a new application from scratch or enhancing an existing one, I bring a problem-solving mindset to the table. My passion is to innovate and find efficient solutions to challenging software puzzles.",
      icon: "fas fa-lightbulb",
    },
  ];

  return (
    <section id="services" className="section bg-light">
      <div className="container">
        {/* Heading */}
        <p className=" text-center mb-2 wow fadeInUp">
          <span className="bg-primary text-dark px-2">Skills</span>
        </p>
        <h2 className="text-10 fw-600 text-center mb-5 wow fadeInUp">
          The Collaborative Developer You Need
        </h2>
        {/* Heading end*/}
        <div className="row gy-5 mt-5">
          {services.length > 0 &&
            services.map((service, index) => (
              <div className="col-sm-6 col-lg-4 wow fadeInUp" key={index}>
                <div className="featured-box text-center px-md-4">
                  <div className="featured-box-icon text-primary text-13">
                    {" "}
                    <i className={service.icon} />
                  </div>
                  <h3 className="text-6 fw-600 mb-3">{service.name}</h3>
                  <p className="text-muted mb-0">{service.desc} </p>
                </div>
              </div>
            ))}
        </div>
      </div>
    </section>
  );
};

export default Services;
