import React from "react";
import resumeFile from "../documents/JosephStanfieldResume.pdf";

const Resume = () => {
  // const educationDetails = [
  //   {
  //     yearRange: "2015 - 2017",
  //     title: "Master in Computer Engineering",
  //     place: "Harvard University",
  //     desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the dummy text.",
  //   },
  //   {
  //     yearRange: "2014 - 2015",
  //     title: "Bachelor in Computer Engineering",
  //     place: "University of California",
  //     desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the dummy text.",
  //   },
  //   {
  //     yearRange: "2013 - 2014",
  //     title: "International Science",
  //     place: "Harvard University",
  //     desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the dummy text.",
  //   },
  // ];

  const experienceDetails = [
    {
      yearRange: "2022 - current",
      title: "Sr. Software Engineer",
      place: "Yum Brands",
      desc: `Develop software for a Fortune 500 company, specializing in one of the fastest-growing restaurant brands globally.
      Mentored junior developers to enhance their skills and improve their performance. Successfully managed the transition
      of React projects to a new backend system utilizing GraphQL, resulting in improved efficiency and productivity.`,
    },
    {
      yearRange: "2022 - current",
      title: "Founder and Developer",
      place: "Bourbon City Web Development",
      desc: `Design and develop a variety of applications for clients, including clothing companies, as well as painting and
      landscaping companies. My projects range from simple informational websites to complex e-commerce websites.
      Additionally, I have assisted clients with search engine optimization (SEO) for their existing sites. By working on diverse
      projects, I have honed my technical expertise and communication skills, allowing me to better understand and meet the
      needs of my clients.`,
    },
    {
      yearRange: "2021 - 2022",
      title: "Software Engineer",
      place: "Churchill Downs",
      desc: `Designed and developed software solutions for the gaming/casino industry using various technologies such as Angular,
      Node, PHP, and Java. I also prepared and delivered technical presentations to stakeholders and business owners,
      ensuring that they understood the technical aspects of the projects. I utilized the agile scrum methodology to manage
      and build my projects, ensuring that they were delivered on time and met all the requirements of the clients.`,
    },
  ];

  // const skills = [
  //   {
  //     name: "Web Design",
  //     percent: 65,
  //   },
  //   {
  //     name: "HTML/CSS",
  //     percent: 95,
  //   },
  //   {
  //     name: "JavaScript",
  //     percent: 80,
  //   },
  //   {
  //     name: "React JS",
  //     percent: 70,
  //   },
  //   {
  //     name: "Angular Js",
  //     percent: 60,
  //   },
  //   {
  //     name: "Bootstrap",
  //     percent: 99,
  //   },
  // ];

  return (
    <section id="resume" className="section">
      <div className="container">
        {/* Heading */}
        <p className=" text-center mb-2 wow fadeInUp">
          <span className="bg-primary text-dark px-2">Resume</span>
        </p>
        <h2 className="text-10 fw-600 text-center mb-5 wow fadeInUp">
          A summary of My Resume
        </h2>
        {/* Heading end*/}
        <div className="row g-5 mt-5">
          {/* My Education */}
          {/* <div className="col-lg-6 wow fadeInUp">
            <h2 className="text-7 fw-600 mb-4 pb-2">My Education</h2>
            <div className="border-start border-2 border-primary ps-3">
              {educationDetails.length > 0 &&
                educationDetails.map((value, index) => (
                  <div key={index}>
                    <h3 className="text-5">{value.title}</h3>
                    <p className="mb-2">
                      {value.place} / {value.yearRange}
                    </p>
                    <p className="text-muted">{value.desc}</p>
                    <hr className="my-4" />
                  </div>
                ))}
            </div>
          </div> */}
          {/* My Experience */}
          <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.2s">
            <h2 className="text-7 fw-600 mb-4 pb-2">My Experience</h2>
            <div className="border-start border-2 border-primary ps-3">
              {experienceDetails.length > 0 &&
                experienceDetails.map((value, index) => (
                  <div key={index}>
                    <h3 className="text-5">{value.title}</h3>
                    <p className="mb-2">
                      {value.place} / {value.yearRange}
                    </p>
                    <p className="text-muted">{value.desc}</p>
                    <hr className="my-4" />
                  </div>
                ))}
            </div>
          </div>
        </div>
        {/* My Skills */}
        {/* <h2 className="text-7 fw-600 mb-4 pb-2 mt-5 wow fadeInUp">My Skills</h2> */}
        {/* <div className="row gx-5">
          {skills.length > 0 &&
            skills.map((skill, index) => (
              <div className="col-md-6 wow fadeInUp" key={index}>
                <p className="fw-500 text-start mb-2">
                  {skill.name}{" "}
                  <span className="float-end">{skill.percent}%</span>
                </p>
                <div className="progress progress-sm mb-4">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{ width: skill.percent + "%" }}
                    aria-valuenow={skill.percent}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  />
                </div>
              </div>
            ))}
        </div> */}
        <p className="text-center mt-5 wow fadeInUp">
          <a
            className="btn btn-outline-dark shadow-none rounded-0"
            href={resumeFile}
            download
          >
            Download CV
          </a>
        </p>
      </div>
    </section>
  );
};

export default Resume;
