import React from "react";
import Accordion from "react-bootstrap/Accordion";

const FAQs = () => {
  const faqsData = [
    {
      question: "What sets you apart from other software engineers?",
      answer: `While I bring to the table a strong technical expertise, having built dynamic, responsive, and accessible applications from scratch, what truly sets me apart are my soft skills. My ability to communicate effectively, collaborate seamlessly, and foster strong relationships with both clients and team members has always been my standout trait. 
      <br></br>This blend of technical prowess and interpersonal skills not only enhances the development process but also ensures the successful delivery of projects that align with stakeholders' visions and goals. This holistic approach is what makes me a unique asset to any team or project.`,
    },
    {
      question: "Which programming languages are you proficient in??",
      answer: `I'm proficient in several programming languages, including JavaScript (with a deep understanding of React and Angular frameworks), PHP, Python, and jQuery.`,
    },
    {
      question: "How long will it typically take to complete my project?",
      answer: `Yes, I'm a Full-Stack Software Developer. I have expertise in front-end technologies, especially cutting-edge JavaScript frameworks like React and Angular. On the back end, I've worked extensively with PHP, Node/Express APIs, and more.`,
    },
    {
      question:
        "How do you ensure the code you write is scalable and maintainable?",
      answer: `I adhere to best coding practices, emphasizing modular and clean code. I also make use of version control systems and prioritize code reviews. This ensures that the applications I build are both scalable and maintainable in the long run.`,
    },
    {
      question: "What's your experience with version control systems like Git?",
      answer: `I regularly use version control systems, with Git being my primary tool. It's essential for collaboration, maintaining code history, and ensuring robust and error-free code deployment.`,
    },
    {
      question: "How do you approach debugging and troubleshooting?",
      answer: `I use a systematic approach to debugging. I first replicate the issue, then isolate the cause using various debugging tools and logs. Once identified, I resolve the problem and run tests to ensure it's fully addressed.`,
    },
    {
      question:
        "Do you have experience with cloud platforms like AWS or Azure?",
      answer: `While my main focus is on software development, I have experience deploying and managing applications on cloud platforms. I can comfortably navigate AWS and Azure environments when required.`,
    },
    {
      question: "How do you approach debugging and troubleshooting?",
      answer: `I use a systematic approach to debugging. I first replicate the issue, then isolate the cause using various debugging tools and logs. Once identified, I resolve the problem and run tests to ensure it's fully addressed.`,
    },
    {
      question:
        "What methodologies do you follow for software development (Agile, Scrum, etc.)?",
      answer: `I believe in Agile and Scrum methodologies. These practices emphasize collaboration, adaptability, and iterative development, ensuring timely delivery of high-quality software.`,
    },
    {
      question:
        "How do you stay updated with the latest tech trends and best practices?",
      answer: `I regularly attend industry conferences, workshops, and webinars. I also participate in online developer communities and follow leading tech blogs and publications to stay abreast of the latest trends.`,
    },
    {
      question:
        "How do you stay updated with the latest tech trends and best practices?",
      answer: `I regularly attend industry conferences, workshops, and webinars. I also participate in online developer communities and follow leading tech blogs and publications to stay abreast of the latest trends.`,
    },
  ];

  return (
    <section id="faq" className="section bg-light">
      <div className="container">
        <div className="row gy-5">
          <div className="col-lg-6 order-1 order-lg-0 wow fadeInUp">
            {/* Heading */}
            <p className="text-center text-lg-start mb-2">
              <span className="bg-primary text-dark px-2">FAQ</span>
            </p>
            <h2 className="text-10 fw-600 text-center text-lg-start mb-5">
              Have a query about my tech expertise?
            </h2>
            {/* Heading end*/}

            <Accordion flush defaultActiveKey="0">
              {faqsData.length > 0 &&
                faqsData.map((faq, index) => (
                  <Accordion.Item eventKey={index} key={index}>
                    <Accordion.Header>{faq.question}</Accordion.Header>
                    <Accordion.Body
                      dangerouslySetInnerHTML={{ __html: faq.answer }}
                    ></Accordion.Body>
                  </Accordion.Item>
                ))}
            </Accordion>
          </div>
          <div
            className="col-lg-6 d-flex align-items-center justify-content-center order-0 order-lg-1 wow fadeIn"
            data-wow-delay="1s"
          >
            {" "}
            <img
              className="img-fluid"
              src="images/faq.png"
              title="FAQ"
              alt="faq"
            />{" "}
          </div>
        </div>
      </div>
    </section>
  );
};

export default FAQs;
