import React from "react";

import Slider from "react-slick";

const Testimonials = () => {
  const reviews = [
    {
      name: "Michael Kerbleski",
      position: "Software Engineer at MX",
      src: "images/testimonial/mike.jpeg",
      desc: "“First and formost Joseph is a joy to be around. He also happens to be a highly skilled programmer that is always pushing himself to write programs that upholds a high standard.”",
    },
    {
      name: "James Klein",
      position: "Sr. Software Engineer at KFC",
      src: "images/testimonial/james.jpeg",
      desc: "“With his exceptional teamwork skills, he goes above and beyond to ensure that project requirements are understood and executed efficiently.”",
    },
    {
      name: "Taiwei Ko",
      position: "Student at Bloomtech",
      src: "images/testimonial/tai.jpeg",
      desc: "“I was very impressed with his depth of programming knowledge, dedication, and willing to help out whenever he can. I was lucky to have Joseph as my mentor.”",
    },
    {
      name: "Elvis Ibara",
      position: "Sr. Engineer at PinataFarms",
      src: "images/testimonial/elvis.jpeg",
      desc: "“His technical abilities and communication skills helped us deliver a great product which exceeded MVP requirements ahead of schedule.”",
    },
    {
      name: "Matthew Wright",
      position: "Software Engineer at InfoSys",
      src: "images/testimonial/matt.jpeg",
      desc: "Joseph has distinct talent for being able to pick up a new library, read the documentation, and implement it quickly.”",
    },
  ];

  const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
    <button
      {...props}
      className={
        "slick-prev slick-arrow" + (currentSlide === 0 ? " slick-disabled" : "")
      }
      aria-hidden="true"
      aria-disabled={currentSlide === 0 ? true : false}
      type="button"
    >
      <i className="fa fa-chevron-left"></i>
    </button>
  );
  const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
    <button
      {...props}
      className={
        "slick-next slick-arrow" +
        (currentSlide === slideCount - 1 ? " slick-disabled" : "")
      }
      aria-hidden="true"
      aria-disabled={currentSlide === slideCount - 1 ? true : false}
      type="button"
    >
      <i className="fa fa-chevron-right"></i>
    </button>
  );

  var settings = {
    dots: true,
    arrows: true,
    prevArrow: <SlickArrowLeft />,
    nextArrow: <SlickArrowRight />,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4500,
  };

  return (
    <section id="testimonial" className="section bg-secondary">
      <div className="container">
        {/* Heading */}
        <p className=" text-center mb-2 wow fadeIn">
          <span className="bg-primary text-dark px-2">Feedback</span>
        </p>
        <h2 className="text-10 fw-600 text-white text-center mb-5 wow fadeIn">
          Peer Feedback
        </h2>
        {/* Heading end*/}
        <div className="row">
          <div className="col-lg-9 mx-auto wow fadeInUp">
            <Slider {...settings}>
              {reviews.length > 0 &&
                reviews.map((review, index) => (
                  <div className="item text-center px-5" key={index}>
                    {" "}
                    <span className="text-9 text-primary">
                      <i className="fa fa-quote-start" />
                    </span>
                    <p className="text-5 text-white">{review.desc}</p>
                    <img
                      style={{ maxWidth: "20%" }}
                      className="img-fluid d-inline-block w-auto rounded-circle shadow"
                      src={review.src}
                      alt={review.name}
                    />{" "}
                    <strong className="d-block text-3 fw-600 text-white">
                      {review.name}
                    </strong>{" "}
                    <span className="text-light">{review.position}</span>{" "}
                  </div>
                ))}
            </Slider>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Testimonials;
